body {	
margin:0;
    padding:0;
    font-family:Camphor,Open Sans,sans-serif;
    font-size: 12px;
    text-decoration: none; 
    color:#000000;  
    line-height: 2;
    scrollbar-width: thin;
    scrollbar-color: var(--thumbBG) var(--scrollbarBG);

}

body::-webkit-scrollbar {
    width: 10px;
  }
   
  body::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }
   
  body::-webkit-scrollbar-thumb {
    background-color: #1572e8;
    outline: 1px solid slategrey;
  }

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, font, strong, b, u, i, center, dd, ol, ul, li, fieldset, form {
border: 0 none;
margin: 0;
outline: 0 none;
padding: 0;
}
.heading{
    font-size: 18px;
}
.headingTwo{
    font-size: 14px;
    font-weight: bold;
}
.test-instructions ul li{
    list-style: none;
    padding-left: 25px;    
} 
 
.test-instructions p{
    padding: 20px 20px 40px 20px;
} 
.test-instructions h3{
    font-size: 18px;
}  
.btmbdrgry{
    border-bottom: 1px solid #CCC;
}
.qbg-not-visited-ins{
    float: left;
    text-align: center;
    padding-top: 1px;
    width: 25px;
    height: 24px;
    font-size: 12px;
    color: #555555 !important;
    margin-right: 3px;
}
.qbg-visited-ins{
    float: left;
    text-align: center;
    padding-top: 1px;
    width: 25px;
    height: 24px;
    font-size: 12px;
    color: #ffffff !important;
    margin-right: 3px;
}
.qbg-ins-label{
    clear: both;
    margin-top: 5px;    
}
.clr{
    clear: both;   
}
.redboldtxt{
    color: #f44336;
    font-weight: bold;   
}
.MuiAppBar-colorPrimary{
    background-color: #1f407a !important;
}
.MuiAccordionRoot .css-ahj2mt-MuiTypography-root{
    font-size: 12px !important;
    font-weight: bold;
}
.MuiAccordionRoot li{
    margin-left: 1rem;
    margin-top: 10px;
}

.topictestlist{
    font-size: 12px;
    font-weight: bold;
    margin-left: 20px;
    margin-top: 10px;
}

.selfTestForm .css-1x51dt5-MuiInputBase-input-MuiInput-input {
    font-size: 12px !important;
}
.selfTestForm .css-aqpgxn-MuiFormLabel-root-MuiInputLabel-root{
    font-size: 12px;
}
.selfTestForm button {
    margin-right: 10px;
    margin-top: 10px;
    text-transform: none;
}
.selectTopicSelfTest{
    border-right: 2px solid #1f407a;
    height: 78vh;
}
.selectTopicSelfTest .css-1x51dt5-MuiInputBase-input-MuiInput-input {
    font-size: 12px !important;
}
.selectTopicSelfTest .css-aqpgxn-MuiFormLabel-root-MuiInputLabel-root{
    font-size: 12px;
}
.selectTopicSelfTest .MuiTreeItem-label{
    font-size: 12px !important;
}
.selectTopicSelfTest ul::-webkit-scrollbar {
    width: 3px;
    height: 3px; 
}
.selectTopicSelfTest ul::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
.selectTopicSelfTest ul::-webkit-scrollbar-thumb {
    background-color: #1572e8;
    outline: 1px solid slategrey;
}

.selectTopicTxt{
    font-weight: bold;
    color: #1f407a;
    font-size: 14px;
}

.selectTopicSelfTest .css-168o85v-MuiAutocomplete-root {
    width: 95% !important;
    margin-bottom: 15px !important;
    margin-right: 4% !important;
}
.selectTopicSelfTest .css-1fxluxt {    
    width: 95% !important;
    margin-bottom: 15px !important;
    margin-right: 4% !important;
}
.selectTopicSelfTest .css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root {
    font-size: 12px !important;
    font-weight: bold !important;
}
.selectTopicSelfTest .css-1d3z3hw-MuiOutlinedInput-notchedOutline{
    font-size: 12px !important;
}

.questionCount{
    margin-left:20px;
    color: #f50057;
    font-weight: bold;
    font-size: 13px;
}


.animate-zoom {animation:animatezoom 0.6s}@keyframes animatezoom{from{transform:scale(0)} to{transform:scale(1)}}

a{ color:#000000;}

.text-white{
    color:#ffffff;
}
.text-black{
    color:#000000;
}
.text-sky{
    color:#36C8FF;     
}

.rightAlign{
    text-align: right;
}
.centerAlign{
    text-align: center;
}

.mrmtcol{
    min-width:40px;
    text-align: center;
    border-bottom: 1px solid #CCC;
}

.lftbdrgry{
    border-left: 1px solid #CCC;
}
.pdlft10{
    padding-left: 10px;
}
.mr5{
    margin-right: 5px !important;
}
.mt10{
    margin-top: 10px !important;
}
.content-justify{
    text-align: justify;
}
.content-left{
    text-align: left;
}
.preWrap{
    white-space:pre-wrap;
}

.tblReport .MuiDataGrid-row{
    cursor: pointer !important;
}
.divBorderBox div{
    border: 1px solid #CCC;
}

.filterSMBox{
    margin-left: 50px;
}
.filterSMBox .css-ffg8md-MuiNativeSelect-select-MuiInputBase-input-MuiOutlinedInput-input{
    padding: 5px 35px 8px 15px !important;    
    font-size: 12px;
}

div.scrolling {
    width:  90vw; /* For 100% screen width */
    overflow: auto;
    white-space: initial;
    word-wrap: break-word;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }
  
  /* Hide scrollbar for Chrome, Safari and Opera */
  div.scrolling::-webkit-scrollbar {
    display: none;
  }
  
.test-name{
    background-color: #555555;
    color: #ffffff;
    border: 1px solid #555555;
    border-radius: 10px;
    padding: 4px;
    font-size: 10px;
}
.bottomBoxFixed {
    position: fixed;
    bottom: 10px;
    width: 100%;    
    margin-top: 10px;
    background-color: #ffffff;
}

.dialogSubmitTestBox .MuiButton-root{
    margin-right: 8px !important;
    min-width: 30px !important;
    height: 25px !important;
    line-height: 0.5 !important;
    font-size: 11px !important;
    text-transform: none !important;
    padding: 10px;
}


.MuiTypography-h6{
    font-size: 15px !important;
}

.materialBox .smitem{
    margin: 8px;
    text-align: center;
    box-shadow: 0px 0px 3px 3px #1572e8;
    font-size: 13px;
    cursor: pointer;
    width: 160px;
    height: 110px;
}
.materialBox .smitem:hover{
    margin: 8px;
    text-align: center;
    box-shadow: 0px 0px 3px 3px #1572e8;
    font-size: 13px;
    cursor: pointer;
    width: 160px;
    height: 110px;
    background-color: #fce7c2;
}
.materialBox i{    
    font-size: 36px;
    color: #f50057;
}

.text-line-1 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1; /* number of lines to show */
    -webkit-box-orient: vertical;
 }
 .text-line-2 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    -webkit-box-orient: vertical;
 }
 .text-line-3 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3; /* number of lines to show */
    -webkit-box-orient: vertical;
 }
 .text-line-4 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4; /* number of lines to show */
    -webkit-box-orient: vertical;
 }
 .text-line-5 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 5; /* number of lines to show */
    -webkit-box-orient: vertical;
 }
 .loginBGImg{
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    -ms-background-size: cover;
    background-attachment: fixed;
    text-align: center;
    height:97vh;
 }
 .mainBox{
     margin-bottom: 100px;
     margin-left: 25px;
     margin-right: 25px;
 }

 .dhslogin {
    display: -webkit-flex;
    display: -webkit-box;
    display: -moz-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    -webkit-box-pack: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    background: #1f407a;
    opacity: 0.8;
    padding: 25px 0 px;
    margin-top: 35px !important;
    height: 420px;
    border-radius: 20px;
}
.loginLogo {
    width: 8rem;
    margin-bottom: 1rem;
}

.flex-container {
    display: flex;
    flex-direction: column;
    width: 70%;
}  
.flex-container > div {
    text-align: center;
    line-height: 10px;
  }

.loginbtn{
    width: 200px;
    background-color: #e49d1b !important;
    color: #ffffff !important;
} 
.loginbtn:hover{
    width: 200px;
    background-color: #e6656a !important;
    color: #ffffff !important;
} 
.loginfrgot{
    color: #ffffff !important;
    font-size: 14px;
} 
.loginfrgot:hover{
    color: #e5f1fc !important;
    font-size: 14px;
}  

.dasbordlive{    
    cursor: pointer;
}
.dasbordlive i{
    color: #e56862;
    font-size: 40px;
    padding: 4px;
    margin-top: 3px;
}
.dasbordlive .colone{
    float: left;    
}
.dasbordlive .coltwo{
    float: left;
    margin-left: 5px;
    background-color: #e56862;
    padding: 3px 3px 3px 8px;
    border-radius: 10px;
    width: 95%;
    color: #fff;
}

.desktop-section{
    display: block;
}
.mobile-section{
    display: none;
}

.sidepanel  {
    width: 250px;
    position: fixed;
    z-index: 99;
    height: 100%;
    top: 0;
    right: 0;
    background-color: #ffffff;
    overflow-x: hidden;
    transition: 0.5s;
    padding-top: 20px;
    border-left: 3px solid #303f9f;
  }
  
  .sidepanel .closebtn {
    position: absolute;
    top: 3px;
    right: 205px;
    font-size: 17px;
    color: #303f9f;
  }
  
  .sidepanelOpen  {
    width: 30px;
    position: fixed;
    z-index: 90;
    height: 20px;
    top: 60px;
    right: 0;
    background-color: #303f9f;
    overflow-x: hidden;
    transition: 0.5s;
    padding-top: 20px;
  }
  .sidepanelOpen .openbtn {
    position: absolute;
    top: 7px;
    right: -15px;
    font-size: 17px;
    color: #ffffff !important;
  }
  
  .anylyticbox{
    min-height: 165px;
    background-color: #2d2d2d;
    border-radius: 10px;
  }
  .anylyticbox h3{
    color: #ffffff;
  }
  .anylyticbox h2{
      text-align: center;
      color: #ffffff;
  }
  .anylyticbox i{       
     color: #23f907;
   } 
   .anylyticbox .lrpadding{       
      padding-left: 50px !important;
      padding-right: 50px !important;
   } 
.anylyticbox .rightalign{       
    text-align: right;
}   
.tmlabel{
    text-align: center;
    color: #000000;
    font-weight: bold;
}
.tblcelltop{
    background-color: #1572e8 !important;
    color: #ffffff !important;
    text-align: right !important;
}
.tblcelltopyou{
    background-color: #574fc5 !important;
    color: #ffffff !important;
    text-align: right !important;
} 
.tblcelltopper{
    background-color: #29bf28 !important;
    color: #ffffff !important;
    text-align: right !important;
}
.tblcelltopavg{
    background-color: #f04951 !important;
    color: #ffffff !important;
    text-align: right !important;
}
.correctmarksbox{    
    text-align: center;
    font-weight: bold;
    color: #0b52ad;
    border-right: 1px solid #CCC;
}
.incorrectmarksbox{    
    text-align: center;
    font-weight: bold;
    color: #ec242e;
    border-right: 1px solid #CCC;
}
.notanswermarksbox{    
    text-align: center;
    font-weight: bold;
    color: #ea8e1d;    
}
.analyticschartlabelcorrect{ 
    font-size: 18px;    
    color: #279411;
}
.analyticschartlabelincorrect{ 
    font-size: 18px;    
    color: #fe3000;
}
.analyticschartlabelnotattempt{ 
    font-size: 18px;    
    color: #f6a034;
}

/* Portrait phones and smaller 480 */
@media (max-width: 767px) {

    .filterSMBox{
        margin-left: 0px;
    }

    .selectTopicSelfTest{
        border-right: none;
    }
    
    .questionPanelFull{
        width: 98%;
    }
    .questionPanelShort{
        width: 98%;
    }

    .textButtonBox .MuiButton-root{
        margin-right: 8px !important;
        min-width: 30px !important;
        height: 23px !important;
        line-height: 0.5 !important;
        font-size: 11px !important;
        text-transform: none !important;
        padding: 7px;
        margin-bottom: 10px;
    }

    .sidepanel  {
        width: 0;
        position: fixed;
        z-index: 99;
        height: 100%;
        top: 0;
        right: 0;
        background-color: #ffffff;
        overflow-x: hidden;
        transition: 0.5s;
        padding-top: 20px;
        border-left: 3px solid #303f9f;
    }
    
    div.scrolling {
        width:  90vw; /* For 100% screen width */
        overflow: auto;
        white-space: nowrap;
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
      }
      
      /* Hide scrollbar for Chrome, Safari and Opera */
      div.scrolling::-webkit-scrollbar {
        display: none;
      }
    
    .bottomBoxFixed {
        position: relative;
        bottom: 10px;
        width: 100%;    
        margin-top: 10px;
        background-color: #ffffff;
    }

    .testMrks{
        text-align: left;
    }
    
    .testTimingBox{
        text-align: left !important;
        margin-top: -10px !important;
    }
    .testTimingBoxLeft{
        text-align: left;
        margin-top: -10px !important;
    }
    

    
    .desktop-section{
        display: none;
    }
    mobile-section{
        display: block;
    }

    

}





.btn-green {background-color: #4CAF50;} /* Green */
.btn-blue {background-color: #008CBA;} /* Blue */
.btn-red {background-color: #f44336;} /* Red */
.btn-gray {background-color: #e7e7e7; color: black;} /* Gray */
.btn-black {background-color: #555555;} /* Black */
  